import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React, { useRef, useEffect, useState } from 'react'
import Arrow from '../../../images/storybook-images/panelsV2/splitpanel/arrow.png'
import { persLookup } from '../../../templates/product-page'
import Link from '../../Link'
import { CaseData, returnMatchingPersonalisation } from '../../Personalisation'
import Word from '../../Personalisation/font'
import Video from '../../panels/Video'

const SectionContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${(props) => props.color ? props.color : '#fff;'};
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  @media (max-width: 1025px) {
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 800px;
  ${(props) => (props.space === 'true' ? 'margin: 8%;width: 34%;' : '')}
  @media (min-width: 1026px) {
    ${(props) => (props.toppadding === 'true' ? 'margin: 5% 0;' : '')}
  }
  @media (max-width: 1025px) {
    width: 100%;
    max-height: 100vw;
    ${(props) => (props.space === 'true' ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const HeaderImageUpsell = styled(GatsbyImage)`
  width: 100%;
  ${(props) => (props.space === 'true' ? 'margin: 8%;width: 34%;' : '')}
  @media (min-width: 1026px) {
    ${(props) => (props.toppadding === 'true' ? 'margin: 5% 0;' : '')}
  }
  @media (max-width: 1025px) {
    display: none;
    width: 100%;
    max-height: 100vw;
    ${(props) => (props.space === 'true' ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const HeaderVideo = styled(Video)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const SectionCopy = styled.div`
  overflow: hidden;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
    z-index: 2;
    ${(props) => (props.paddingOn ? 'padding: 32px 0;' : '')};

    
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) => (props.center ? 'text-align: center; align-items: center;' : '')}
  }
`
const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 80%;
    ${(props) => (props.TextAlignment ? `text-align: ${props.TextAlignment};` : '')};
  }
`
const Header = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.71px;
  color: rgb(var(--${(props) => props.color}));
  justify-content: center;
  letter-spacing: -0.66px;
  display: flex;
  text-transform: uppercase;
  @media (max-width: 1025px) {
    font-size: 11px;
    padding: 0;
    margin-bottom: 8px; 
  }
`
const Title = styled.div`
  display: flex;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.11px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  padding: 8px 0;
  justify-content: center;
  display: flex;
  text-align: center;
  @media (max-width: 1025px) {
    padding-top: 0;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`
const CopyBody = styled.div`
  margin: 0 auto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.12px;
  color: #000;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.39;
  max-width: 600px;
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.07px;
    color: rgb(var(--black));
    margin-right: 48px;
    margin: 0;
    padding: 0 20px;
  }

  & a {
    text-decoration: underline !important;
  }

  & p {
	  margin: 16px 0;
	}
`
const LinkContainer = styled.div`
  margin: 24px auto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.12px;
  color: rgb(var(--black));
  justify-content: center;
  display: flex;
  text-align: center;
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.07px;
    color: rgb(var(--black));
    margin-right: 48px;
    margin: 0;
    padding: 20px;
    padding-bottom: 0;
  }

  & a {
    text-decoration: underline !important;
    color: #000;
  }

  & p {
	  margin: 16px 0;
	}
`
const UpsellContainer = styled.div`
  margin: 0 auto;
  justify-content: center;
  display: flex;

`
const UpsellItemContainer = styled.div`
  mix-blend-mode: darken;
  margin: 16px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.12px;
  color: rgb(var(--black));
  justify-content: start;
  display: inline-grid;
  max-width: 25vw;
  text-align: center;
  font-weight: 400;
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.07px;
    color: rgb(var(--black));
    margin-right: 48px;
    margin: 0;
    padding: 0 20px;
    max-width: 50vw;
  }

  & a {
    text-decoration: underline !important;
    color: #000;
  }

  & p {
	  margin: 16px 0;
	}
`
const StaticHeaderImage = styled.img`
  width: 50%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`
const LabelTag = styled.div`
  background: white;
  color: #000;
  border-radius: 25px;
  padding: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  letter-spacing: -0.66px;
  margin: 20px;
`;
const TextInput = styled.input`
  font-size: 18px;
  font-family: Objectivity;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.52px;
  color: #191919;
  border: none;
  outline: none;
  width: calc(100% - 16px);
  @media (max-width: 1025px) {
    text-align: center;
  }
`

const CaseTextContainerPositions = (location, caseSize, font) => {
  const values = {
    Top: {
      Carry: `
      top: 6%;
      left: 0;
      right: 0;
    `,
      Pro: `
      top: 13%;
      left: 0;
      right: 0;
    `,
      Light: `
      top: 11%;
      left: 0;
      right: 0;
    `,
      Checked: `
      top: 8%;
      left: 0;
      right: 0;
    `,
      Plus: `
      top: 11%;
      left: 0;
      right: 0;
    `,
    },
    Back: {
      Carry: `
      top: 78%;
      ${font === 'Block' ? 'top: 76%;' : ''}
      left: 0;
      right: 0;
    `,
      Light: `
        top: 78%;
        ${font === 'Block' ? 'top: 76%;' : ''}
        left: 0;
        right: 0;
      `,
      Pro: `
      top: 78%;
      ${font === 'Block' ? 'top: 76%;' : ''}
      left: 0;
      right: 0;
    `,
      Checked: `
      top: 68%;
      ${font === 'Block' ? 'top: 65%;' : ''}
      left: 0;
      right: 0;
    `,
      Plus: `
      top: 65%;
      ${font === 'Block' ? 'top: 62%;' : ''}
      left: 0;
      right: 0;
    `,
      Tote: `
      top: 69%;
      left: -2%;
      right: 0;
    `,
      Backpack: `
      top: 62%;
      left: 1.5%;
      right: 0;
    `,
      Daypack: `
      top: 58%;
      left: -1%;
      right: 0;
    `,
      Weekender: `
      top: 63%;
      left: 0%;
      right: 0;
    `,
      Me: `
      top: 62%;
      ${font === 'Block' ? 'top: 61%;' : ''}
      left: 25%;
      right: 0;
    `,
    },
    Side: {
      Carry: `
      transform: rotate(-90deg);
      bottom: 19%;
      left: -19.55%;
      right: 0%;
    `,
      Pro: `
      transform: rotate(-90deg);
      bottom: 19%;
      left: -19.55%;
      right: 0%;
    `,
      Light: `
        transform: rotate(-90deg);
        bottom: 24%;
        left: -19.55%;
        right: 0%;
      `,
      Checked: `
      transform: rotate(-90deg);
      bottom: 18%;
      left: -24.55%;
      right: 0%;
    `,
      Plus: `
      transform: rotate(-90deg);
      bottom: 18%;
      left: -23%;
      right: 0;
      ${font === 'Block' ? 'left: -22%;' : ''}
    `,
    },
  }
  return values[location][caseSize]
}
const LocationData = (positions, size) => {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    ${positions.top && `top: ${positions.top};`}
    ${positions.right && `right: ${positions.right};`}
    ${positions.bottom && `bottom: ${positions.bottom};`}
    ${positions.left && `left: ${positions.left};`}

    ${positions.transform && `transform: ${positions.transform};`}

    width: ${size.width};
    height: ${size.height};
  `
}

const CaseTextContainer = styled.div`  
  position: absolute;
  ${(props) =>
    !props.locationData && props.font !== 'Block' ? 'padding-top: 16px;' : ''}
  ${(props) => {

    if (props.locationData) {
      return LocationData(props.locationData, props.size)
    } else {
      return CaseTextContainerPositions(
        props.location,
        props.caseSize,
        props.font
      )
    }
  }}

  @media (max-width: 768px) {

  }
`

const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
`

const fetchPreviousPersonalisation = (caseSize) => {
  try {
    const matchingPrevious = returnMatchingPersonalisation(caseSize)
    if (matchingPrevious && matchingPrevious[0] !== undefined) {
      let persData = matchingPrevious[0]
      return persData
    }
    return undefined
  } catch (e) {
    console.log(e)
  }
}

const SplitPanel = ({ header, title, body, link, addPersonalisationOptionOn, upsellItem, color, font, Image, reverse, personalisationSelection, overlapCompensation }) => {
  const skuLoopupValue = persLookup[personalisationSelection?.SKU]
  const previousPers = fetchPreviousPersonalisation(skuLoopupValue?.product)
  const caseLookupValue = CaseData[skuLoopupValue?.product]
  const productLocationData = caseLookupValue?.locations
  const locationData = productLocationData && productLocationData[personalisationSelection?.Location]
  const [persData, setPersData] = React.useState(previousPers?.text || personalisationSelection?.Placeholder || 'Lily')
  const updatedPersImage = personalisationSelection ? `https://cdn.july.com/personalisation/cases/${caseLookupValue?.imageName && caseLookupValue?.imageName.toLowerCase()
    }/${personalisationSelection?.Location && personalisationSelection?.Location.toLowerCase()}/${skuLoopupValue.color_id + 1 < 10 ? '0' : ''
    }${skuLoopupValue.color_id + 1}.webp` : undefined

  const wordContainerRef = useRef(null);
  const [isWordVisible, setIsWordVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsWordVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      });
    });

    if (wordContainerRef.current) {
      observer.observe(wordContainerRef.current);
    }

    return () => {
      if (wordContainerRef.current) {
        observer.unobserve(wordContainerRef.current);
      }
    };
  }, []);


  return (
    <SectionContainer
      color={color}
      mobilePadding={true}
      reverse={reverse === 'Right'}
      style={{ ...(overlapCompensation ? { paddingBottom: 50 } : {}) }}
    >
        <SectionCopy>
          {addPersonalisationOptionOn && (
            <LabelTag style={{ marginTop: '40px', paddingLeft: 24 }}>
              <TextInput value={persData} onChange={(e) => setPersData(e.target.value)} /><img src={Arrow} alt={Arrow} style={{ width: 10 }} />
            </LabelTag>
          )}
          <PreviewContainer>
            {addPersonalisationOptionOn && (
              <div style={{ marginBottom: '-25%', position: 'relative' }} ref={wordContainerRef}>
                {isWordVisible && (
                  <CaseTextContainer size={locationData?.displaySize} locationData={locationData}>
                    <Word
                      maxLetters={12}
                      size={locationData?.displaySize}
                      letters={persData}
                      fontSize={15}
                      mainCol={previousPers?.color || personalisationSelection.Colour}
                      backCol={previousPers?.shadow || personalisationSelection.Shadow}
                      font={previousPers?.font || personalisationSelection.Font.toLowerCase().split('_').join('-')}
                    />
                  </CaseTextContainer>
                )}
                {
                  updatedPersImage && (
                    <StaticHeaderImage loading="lazy" src={updatedPersImage || Image?.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  )
                }
              </div>
            )}
            {
              upsellItem ? (
                <Link to={link} noLink={link === null}>
                  {Image && Image?.file && (
                    Image?.mime?.includes('video') || Image?.mime?.includes('gif') ? (
                      <HeaderVideo
                        alt={Image?.alternativeText || Image?.name}
                        src={Image?.file?.publicURL}
                      />
                    ) : (
                      <HeaderImage
                        loading="lazy"
                        alt={Image?.alternativeText || Image?.name}
                        image={withArtDirection(getImage(Image?.file?.childImageSharp?.gatsbyImageData), [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                          },
                        ])}
                      />
                    )
                  )}
                </Link>
              ) : (
                <Link to={link?.to} noLink={link?.to === null}>
                  {Image && Image?.file && (
                    Image?.mime?.includes('video') || Image?.mime?.includes('gif') ? (
                      <HeaderVideo
                        alt={Image?.alternativeText || Image?.name}
                        src={Image?.file?.publicURL}
                      />
                    ) : (
                      <HeaderImage
                        loading="lazy"
                        alt={Image?.alternativeText || Image?.name}
                        image={withArtDirection(getImage(Image?.file?.childImageSharp?.gatsbyImageData), [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                          },
                        ])}
                      />
                    )
                  )}
                </Link>
              )
            }

          </PreviewContainer>

        </SectionCopy>
      <SectionCopy paddingOn={true}>
        <CopyWidthContainer>
          {
            header && (
              <Header color="black">
                {header}
              </Header>
            )
          }
          {
            title && (
              <Title color="black">
                {title}
              </Title>
            )
          }
          {
            body && (
              <CopyBody color="black" dangerouslySetInnerHTML={{ __html: body.replace(/&nbsp;/g, '') }} />
            )
          }
          {
            link && (
              <LinkContainer>
                <Link to={link.to}>
                  {link.label}
                </Link>
              </LinkContainer>
            )
          }
          {
            upsellItem && (
              <UpsellContainer>
                {Object.values(upsellItem)?.map((item, key) => (
                  <Link to={item.to} key={key}>
                    <UpsellItemContainer>
                      {
                        item.image.isStatic ? (
                          <StaticHeaderImage src={item.image.url} style={{ width: '15vw' }}
                          />
                        ) : (
                          <>
                            {item.image && (
                              item.image.mime?.includes('video') || item.image.mime?.includes('gif') ? (
                                <HeaderVideo
                                  alt={item.image.alternativeText || item.image.name}
                                  src={item.image.file?.publicURL}
                                  style={{ width: '15vw' }}
                                />
                              ) : (
                                <HeaderImageUpsell
                                  style={{ width: '15vw' }}
                                  loading="lazy"
                                  alt={item.image.alternativeText || item.image.name}
                                  image={withArtDirection(getImage(item.image.file?.childImageSharp?.gatsbyImageData), [
                                    {
                                      media: '(max-width: 1024px)',
                                      image: getImage(item.image.file?.childImageSharp?.gatsbyImageDataMobile),
                                    },
                                  ])}
                                />
                              )
                            )}
                          </>
                        )
                      }
                      <span style={{ textDecoration: 'underline' }}>{item.label}</span>
                    </UpsellItemContainer>
                  </Link>
                ))}
              </UpsellContainer>
            )
          }
        </CopyWidthContainer>
      </SectionCopy>
    </SectionContainer>
  )
}

export default SplitPanel
